import { IChoiceGroupOption } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IDocksQueued, IGenericPayload } from "../../../../../../models";
import { IConfigEditRequest } from "../../../../../../models/requests/IConfigEditRequest";
import { fetchDocksQueued } from "../../../../../../redux/actions/catalog/catalog";
import { updateRequestDockId, updateRequestFormData } from "../../../../../../redux/actions/request/edit/action/requestEdit";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { ButtonPrimaryInverse } from "../../../../../common/buttons/buttons";
import { CustomIcons } from "../../../../../common/customIcons/customIcons";
import { PopUpForm } from "../../../../../common/popup/popUpForm/popUpForm";
import { RadioButtonGroup } from "../../../../../common/radioButton/radioButtonGroup/radioButtonGroup";
import { Separator } from "../../../../../common/separator/separator";
import { DockAssignerStyles } from "./dockAssigner.jss";
import { DockAssignerPopUp } from "./dockAssignerPopUp/dockAssignerPopUp";
import { DirectionType } from "../../../../../../common/enum/DirectionType";

interface IProps {
    schema: IConfigEditRequest;
    dockAssignment: boolean;
}

export const DockAssigner: React.FC<IProps> = ({ schema, dockAssignment }) => {
    const theme = useTheme();
    const styles = DockAssignerStyles({ theme });
    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST.DOCKASSIGNER" });
    const dispatch = useAppDispatch();
    const request = useAppSelector((store) => store.editRequest.headerRequest);
    const docks = useAppSelector((store) => store.catalog.docksQueued);

    const [optionDockId, setOptionDockId] = useState<number>(0);
    const [isDockAssignerPopUpVisible, setDockAssignerPopUpVisible] = useState<boolean>(false);

    const selectableDocks = docks
        .filter((item) => {
            return item.selectable || item.id === request.dockId;
        })
        .map((item) => {
            return {
                key: item.id.toString(),
                text: `${item.description} (${item.queued === 0 ? t("FREE_DOCK") : `${item.queued} ${t("IN_QUEUE")}`})`,
            } as IChoiceGroupOption;
        });

        useEffect(() => {
            if (request.merchandiseTypeId !== null && request.merchandiseTypeId !== undefined &&
                request.merchandiseOriginId !== null && request.merchandiseOriginId !== undefined &&
                request.humanConsumption !== null && request.humanConsumption !== undefined &&
                request.fumigated !== null && request.fumigated !== undefined &&
                request.halalCertified !== null && request.halalCertified !== undefined &&
                request.organicProduct !== null && request.organicProduct !== undefined &&
                request.dryCharge !== null && request.dryCharge !== undefined) {
                const params: IDocksQueued = { 
                    merchandiseTypeId: request.merchandiseTypeId,
                    merchandiseOriginId: request.merchandiseOriginId,
                    humanConsumption: request.humanConsumption,
                    fumigated: request.fumigated,
                    halalCertified: request.halalCertified,
                    organicProduct: request.organicProduct,
                    dryCharge: request.dryCharge,
                }
                dispatch(fetchDocksQueued(params));
            }
        }, [dispatch, request.merchandiseTypeId, request.merchandiseOriginId, request.humanConsumption]);
    const currentDock = () => {
        if (request.dockId && docks.length) {
            return docks.find((item) => {
                return item.id === request.dockId;
            });
        }

        return undefined;
    };

    const [docksVisible, { toggle: onToggleDocks }] = useBoolean(false);

    const onUpdateDock = useCallback(
        (optionKey?: string, _name?: any) => {
            const payload: IGenericPayload = { name: "dockId", value: Number(optionKey) };
            onToggleDocks();
            dispatch(updateRequestFormData(payload));
            dispatch(updateRequestDockId(request, Number(optionKey)));
        },
        [dispatch, onToggleDocks, request],
    );

    const onCloseDockAssignerPopUp = () => {
        setDockAssignerPopUpVisible(false);
        setOptionDockId(0);
    };
    const onSaveDockAssignerPopUp = () => {
        if (optionDockId) {
            const payload: IGenericPayload = { name: "dockId", value: optionDockId };
            onToggleDocks();
            dispatch(updateRequestFormData(payload));
            dispatch(updateRequestDockId(request, optionDockId));
            onCloseDockAssignerPopUp();
        }
    };

    return (
        <>
            {schema.dockId.visible && (dockAssignment || currentDock()) && (
                <>
                    {!schema.dockId.readonly && dockAssignment ? (
                        <>
                            <PopUpForm
                                isVisible={isDockAssignerPopUpVisible}
                                onCancel={onCloseDockAssignerPopUp}
                                content={<DockAssignerPopUp setDockIdOption={setOptionDockId} />}
                                height={"auto"}
                                width={"auto"}
                                title={t("DOCK_LIST")}
                                onSave={onSaveDockAssignerPopUp}
                            />
                            <div className={styles.container}>
                                <div className={styles.dockAssigner}>
                                    <div
                                        className="selector"
                                        onClick={onToggleDocks}
                                    >
                                        <div className="description">
                                            {currentDock()
                                                ? `${currentDock()!.description} (${
                                                      currentDock()!.queued === 0 ? t("FREE_DOCK") : `${currentDock()!.queued} ${t("IN_QUEUE")}`
                                                  })`
                                                : t("SELECT_DOCK")}
                                        </div>
                                        <CustomIcons
                                            className={docksVisible ? "arrowUp" : "arrow"}
                                            iconName="ChevronDownIcon"
                                            margin={"0px"}
                                        ></CustomIcons>
                                    </div>
                                    {docksVisible && (
                                        <div className="actionMenu">
                                            {selectableDocks.length > 0 && (
                                                <RadioButtonGroup
                                                    selectedKey={request.dockId?.toString()}
                                                    options={selectableDocks}
                                                    onChange={onUpdateDock}
                                                    direction={DirectionType.vertical}
                                                />
                                            )}
                                            <Separator
                                                horizontal={true}
                                                size="100%"
                                            />
                                            <ButtonPrimaryInverse
                                                handleClick={() => setDockAssignerPopUpVisible(true)}
                                                title={t("VIEW_ALL_DOCKS")}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            {currentDock() && (
                                <div className={styles.container}>
                                    <div className={styles.dockAssigner}>
                                        <div className="selector noPointer">
                                            <div className="description">
                                                {currentDock()
                                                    ? `${currentDock()!.description} (${
                                                          currentDock()!.queued === 0 ? t("FREE_DOCK") : `${currentDock()!.queued} ${t("IN_QUEUE")}`
                                                      })`
                                                    : t("SELECT_DOCK")}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
};
